import React from "react";
import { MainContent } from "../components"

function Home() {
    
    return (
        <MainContent/>
    );
}

export default Home;