import React from "react";
import { OrganicaContent } from "../components"

function OrganicaPage() {
    
    return (
        <OrganicaContent />
    );
}

export default OrganicaPage;